import ndvLogo from '../assets/icons/nvidia-logo.png';
import ndvLogoT from '../assets/icons/nvidia-logo-t.png';
import awsLogo from '../assets/icons/aws-logo.png';

const index = () => {
  return (
    <section className='container lg:w-[60%] md:w-[80%] mx-auto pt-40 px-4 md:px-0'>
      <div className='space-y-5'>
        <h2 className='bg-[#F1F0EE] py-3 px-8 rounded-3xl w-fit mx-auto mb-8'>
          What are we doing?
        </h2>
        <p className='text-lg'>
          We are dedicated to bridging communication gaps between English and
          Nigerian languages, through innovative AI-powered translation
          solutions. Our mission is to empower individuals and businesses across
          Nigeria by breaking down language barriers and preserving our rich
          cultural heritage. We envision a world where language differences no
          longer hinder communication, commerce, or cultural exchange.
        </p>
        <p className='text-lg'>
          Our commitment goes beyond mere translation; we're building bridges
          between generations, communities, and businesses, ensuring that our
          indigenous languages thrive in today's digital age while facilitating
          clear communication with the English-speaking world. Together, we're
          creating a future where language enriches rather than divides, and
          where technology serves as a guardian of our cultural heritage while
          powering modern communication needs.
        </p>
      </div>

      <div className='mt-24'>
        <p className='bg-[#F1F0EE] py-2 px-8 rounded-3xl w-fit mx-auto mb-8'>
          Partners
        </p>
        <section className='text-center flex flex-row justify-center  my-10 space-x-4'>
          <img
            src={ndvLogo}
            alt=''
            className='md:w-28 md:h-8 w-24 h-6 object-contain my-auto'
          />
          <img
            src={awsLogo}
            alt=''
            className='md:w-28 md:h-8 w-24 h-6 object-contain my-auto'
          />
          <img
            src={ndvLogoT}
            alt=''
            className='md:w-28 md:h-12 w-24 h-12 object-contain my-auto'
          />
        </section>
      </div>
    </section>
  );
};

export default index;
