import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Layout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <section>
      <Navbar />
      {children}
      <Footer />
    </section>
  );
}
