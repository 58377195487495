import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <section className='container md:w-[76%] mx-auto md:pt-40 pt-20 px-4 md:px-0'>
      <div className='md:mt-0 md:mb-8 mt-12 mb-6'>
        <p className='bg-[#F1F0EE] py-2 px-8 text-sm text-[#FCC01D] rounded-3xl w-fit mx-auto mb-8'>
          Last updated: 12th December 2024
        </p>
        <h1 className='md:text-5xl text-3xl text-center'>
          TerngiAI Privacy Policy
        </h1>
      </div>

      <div className='space-y-6'>
        <div>
          <h2 className='mb-1'>Welcome to TerngiAI!</h2>
          <p>
            At TerngiAI, we are committed to protecting your privacy and
            ensuring transparency in how your data is collected, used, and
            secured.
          </p>
        </div>

        <div>
          <h2> This Privacy Policy explains:</h2>
          <ul className='list-disc ms-5'>
            <li>The types of data we collect.</li>
            <li>How we use and store the data.</li>
            <li>Your rights concerning your personal information.</li>
          </ul>
          <p>By using our platform, you consent to this Privacy Policy</p>
        </div>

        <div>
          <h2>How We Use Your Data</h2>
          <p>We use your data for the following purposes:</p>
          <ul className='list-disc ms-5'>
            <li>Service Delivery: To process and provide translations.</li>
            <li>
              AI Model Improvement: Anonymized user-uploaded data may be used to
              enhance our language models.
            </li>
            <li>
              User Experience: To personalize content, troubleshoot issues, and
              improve services.
            </li>
          </ul>
          <p>Compliance: To comply with legal and regulatory requirements.</p>
        </div>

        <div>
          <h2>Data Sharing and Disclosure</h2>
          <p>
            We do not sell your data to third parties. Data sharing occurs only
            in the following circumstances:
          </p>
          <ul className='list-disc ms-5'>
            <li>With Consent: When users grant explicit permission.</li>
            <li>
              Service Providers: Third-party partners assisting in hosting,
              analytics, or payment processing.
            </li>
          </ul>
          <p>
            Legal Requirements: If required by law or to protect TerngiAI’s
            rights and property.
          </p>
        </div>

        <div>
          <h2>User-Uploaded Data for AI Training</h2>
          <ul className='list-disc ms-5'>
            <li>
              Any content you upload may be anonymized and used to train
              TerngiAI’s AI models.
            </li>
            <li>
              Anonymized data ensures that personal identifiers are removed
              before use.
            </li>
            <li>By uploading content, you consent to this use</li>
          </ul>
        </div>

        <div>
          <h2>Data Security</h2>
          <p>
            We implement industry-standard measures to protect your data,
            including:
          </p>
          <ul className='list-disc ms-5'>
            <li>Encryption for data transmission and storage.</li>
            <li>Regular audits and vulnerability assessments.</li>
          </ul>
        </div>
        <div>
          <h2>Your Rights</h2>
          <p>You have the following rights concerning your data:</p>
          <ul className='list-disc ms-5'>
            <li>Access: Request a copy of your data.</li>
            <li>Correction: Update or correct inaccuracies.</li>
            <li>
              Deletion: Request deletion of your data, except anonymized data
              used for AI training.
            </li>
            <li>
              Withdraw Consent: Opt-out of data processing where applicable.
            </li>
          </ul>
          <p>
            To exercise these rights, email us at{' '}
            <Link
              target='_blank'
              className='underline'
              to='mailto:privacy@terngi.ai.'>
              privacy@terngi.ai.
            </Link>{' '}
          </p>
        </div>

        <div>
          <h2>Payment and Subscriptions</h2>
          <ul className='list-disc ms-5'>
            <li>
              Free and Paid Services: Some features are free, while others
              require payment.
            </li>
            <li>
              Refund Policy: Payments are non-refundable unless otherwise
              stated.
            </li>
          </ul>
        </div>

        <div>
          <h2>Cookies</h2>
          <p>Our website uses cookies to:</p>
          <ul className='list-disc ms-5'>
            <li>Track user interactions for analytics.</li>
            <li>Enhance platform performance and personalization.</li>
          </ul>
          <p>
            You can disable cookies in your browser settings, but this may
            affect functionality.
          </p>
        </div>

        <div>
          <h2>Data Retention</h2>
          <p>Our website uses cookies to:</p>
          <ul className='list-disc ms-5'>
            <li>
              Personal data is retained for as long as necessary to provide
              services.
            </li>
            <li>
              Uploaded data used for AI training is retained in an anonymized
              format indefinitely.
            </li>
          </ul>
        </div>

        <div>
          <h2>Changes to this Privacy Policy</h2>

          <ul className='list-disc ms-5'>
            <li>
              Updates to this policy will be posted here with the revised date.
            </li>
            <li>
              Continued use of the platform after changes implies acceptance.
            </li>
          </ul>
        </div>
        
        <div>
          <p>Contact Us</p>
          <ul className='list-disc ms-5'>
            <li>
              If you have questions about these Terms, please email us at{' '}
              <Link
                target='_blank'
                className='underline'
                to='mailto:support@terngi.ai'>
                support@terngi.ai
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
