import heroBg from '../assets/banner-bg.png';
import ndvLogo from '../assets/icons/nvidia-logo.png';
import ndvLogoT from '../assets/icons/nvidia-logo-t.png';
import awsLogo from '../assets/icons/aws-logo.png';
import { AccessFormModal } from './AccessForm';
import { useState } from 'react';
import EarlyAccessButton from '../common/EarlyAccessButton';

const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <main>
      <section
        className=''
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh',
        }}>
        {isModalOpen && (
          <AccessFormModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
        <div className='md:mt-48 mt-32 text-center px-2'>
          <p>With terngi</p>
          <h1 className=' my-auto md:text-5xl text-3xl font-medium tracking-wider md:leading-relaxed mt-5 md:mt-0 '>
            You <i className='font-bold'>speak</i> the same language
          </h1>
          <p className='mt-2 text-lg'>
            Terngi bridges communication gaps, enabling seamless conversations
            across multiple languages. allowing you
            <br className='hidden md:block' /> to speak the same language no
            matter where you are.
          </p>

          <section className='mx-auto'>
            <EarlyAccessButton onClick={() => setIsModalOpen(true)} />
          </section>
          <section className='text-center flex flex-row justify-center md:mt-40 mt-24 mb-20 space-x-4 my-auto'>
            <img
              src={ndvLogo}
              alt=''
              className='md:w-28 md:h-8 w-24 h-6 object-contain my-auto'
            />
            <img
              src={awsLogo}
              alt=''
              className='md:w-28 md:h-8 w-24 h-6 object-contain my-auto'
            />
            <img
              src={ndvLogoT}
              alt=''
              className='md:w-28 md:h-14 w-24 h-12 object-contain my-auto'
            />
          </section>
        </div>
      </section>
    </main>
  );
};

export default Banner;
