import { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { AccessFormModal } from './AccessForm';

const Business = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className='container mx-auto lg:w-[80%] md:w-[94%] w-[985] px-4 md:px-0 mt-28 md:mt-0'>
      {isModalOpen && (
        <AccessFormModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <>
        <div className='text-center'>
          <h1 className='text-3xl'>More to Terngi</h1>
          <p className='mt-4 mb-8'>
            We believe powerful translation tools should enhance your business,
            not complicate it.{' '}
          </p>
        </div>
        <div className='md:py-12 py-8 md:text-center rounded-2xl px-5 group relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
          <h1 className='md:text-2xl text-xl'>
            Power up your business systems <br className='hidden md:block' />{' '}
            with our API
          </h1>
          <p className='font-extralight my-3 md:w-3/4 mx-auto'>
            Take your business to the next level with Terngi’s API. Enable
            instant translation across your platforms – from customer chats to
            global contracts. Our API allows your software to speak every
            language your business needs, ensuring smooth communication across
            diverse markets and clients.
          </p>

          <button
            onClick={() => setIsModalOpen(true)}
            className='bg-black text-white rounded-lg px-6 py-3 md:mt-12 mt-8 flex font-light md:mx-auto'>
            Get early access{' '}
            <span className='my-auto ms-3'>
              <FaArrowRight />
            </span>
          </button>
        </div>
      </>
    </section>
  );
};

export default Business;
