import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <section className='container md:w-[76%] mx-auto md:pt-40 pt-20 px-4 md:px-0'>
      <div className='md:mt-0 md:mb-8 mt-12 mb-6'>
        <p className='bg-[#F1F0EE] py-2 px-8 text-sm text-[#FCC01D] rounded-3xl w-fit mx-auto mb-8'>
          Last updated: 12th December 2024
        </p>
        <h1 className='md:text-5xl text-3xl text-center'>
          TerngiAI Terms Of Service
        </h1>
      </div>

      <div className='space-y-6'>
        <div>
          <h2 className='mb-1'>Welcome to TerngiAI!</h2>
          <p>
            These Terms and Conditions govern your use of the TerngiAI platform.
            By accessing or using our services, you agree to these terms. If you
            do not agree, please refrain from using our platform.
          </p>
        </div>

        <div>
          <p> Acceptance of Terms</p>
          <ul className='list-disc ms-5'>
            <li>
              By accessing or using TerngiAI, you acknowledge that you have
              read, understood, and agree to these Terms and Conditions.
            </li>
            <li>
              These terms apply to all users, including visitors, account
              holders, and organizations using our services.
            </li>
          </ul>
        </div>

        <div>
          <p>Eligibility</p>
          <ul className='list-disc ms-5'>
            <li>
              You must be at least 18 years old to use our platform or have
              parental/guardian consent.
            </li>
            <li>
              By using the platform, you confirm that you meet the eligibility
              criteria.
            </li>
          </ul>
        </div>

        <div>
          <p>User Accounts</p>
          <ul className='list-disc ms-5'>
            <li>
              Account Creation: Users must provide accurate and up-to-date
              information when registering.
            </li>
            <li>
              Account Security: You are responsible for keeping your login
              credentials confidential. Notify us immediately of unauthorized
              access or breaches.
            </li>
            <li>
              Account Suspension or Termination: We reserve the right to suspend
              or terminate accounts violating these Terms or engaging in illegal
              or harmful activities.
            </li>
          </ul>
        </div>

        <div>
          <p>
            Permitted Use <br /> You agree to:
          </p>
          <ul className='list-disc ms-5'>
            <li>Use the platform only for lawful purposes.</li>
            <li>
              Account Security: You are responsible for keeping your login
              credentials confidential. Notify us immediately of unauthorized
              access or breaches.
            </li>
            <li>
              Not misuse the platform by engaging in fraudulent, abusive, or
              harmful activities, including:
            </li>
          </ul>
          <ul className='list-disc ms-10'>
            <li>Uploading harmful, obscene, or infringing content.</li>
            <li>
              Attempting to reverse-engineer, hack, or disrupt the platform's
              functionality.
            </li>
            <li>
              Violating the intellectual property rights of TerngiAI or third
              parties.
            </li>
          </ul>
        </div>

        <div>
          <p>Services Provided</p>
          <ul className='list-disc ms-5'>
            <li>
              Translation Services: Real-time and document-based translation
              services.
            </li>
            <li>
              AI Training and Improvement: Uploaded data may be anonymized and
              used to train and improve TerngiAI’s language models (see Privacy
              Policy).
            </li>
            <li>
              Service Limitations: While we strive for high accuracy,
              translations may not always be perfect or contextually
              appropriate.
            </li>
          </ul>
        </div>
        <div>
          <p>Intellectual Property</p>
          <ul className='list-disc ms-5'>
            <li>
              Ownership: All platform content, including text, code, graphics,
              and AI models, is owned by TerngiAI and protected under
              intellectual property laws.
            </li>
            <li>
              User Content: By uploading content, you grant us a non-exclusive,
              worldwide, royalty-free license to process, anonymize, and use the
              content for service delivery and AI improvement.
            </li>
          </ul>
        </div>

        <div>
          <p>Privacy and Data Usage</p>
          <ul className='list-disc ms-5'>
            <li>
              By using TerngiAI, you agree to the collection, use, and sharing
              of data as outlined in our Privacy Policy.
            </li>
            <li>
              Uploaded data may be used to enhance the platform's capabilities
              through AI training, provided it is anonymized and secured.
            </li>
          </ul>
        </div>

        <div>
          <p>Payment and Subscriptions</p>
          <ul className='list-disc ms-5'>
            <li>
              Free and Paid Services: Some features are free, while others
              require payment.
            </li>
            <li>
              Refund Policy: Payments are non-refundable unless otherwise
              stated.
            </li>
          </ul>
        </div>

        <div>
          <p>Limitation of Liability</p>
          <ul className='list-disc ms-5'>
            <li>
              TerngiAI is not liable for indirect, incidental, or consequential
              damages arising from your use of the platform.
            </li>
            <li>
              You assume full responsibility for decisions made based on
              translations or services provided by the platform.
            </li>
          </ul>
        </div>

        <div>
          <p>Updates to the Terms</p>
          <ul className='list-disc ms-5'>
            <li>
              We may update these Terms periodically. Updates will be effective
              upon posting on this page. Users are responsible for reviewing the
              Terms regularly.
            </li>
          </ul>
        </div>

        <div>
          <p>Governing Law</p>
          <ul className='list-disc ms-5'>
            <li>
              These Terms and Conditions are governed by the laws of Nigeria.
              Any disputes will be subject to the jurisdiction of Nigerian
              courts.
            </li>
          </ul>
        </div>

        <div>
          <p>Contact Us</p>
          <ul className='list-disc ms-5'>
            <li>
              If you have questions about these Terms, please email us at{' '}
              <Link
                target='_blank'
                className='underline'
                to='mailto:support@terngi.ai'>
                support@terngi.ai
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Terms;
