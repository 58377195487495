import { useState } from "react";

interface FormData {
  fullName: string;
  email: string;
  message: string;
}

interface FormErrors {
  fullName?: string;
  email?: string;
  message?: string;
}

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "success" | "error"
  >("idle");

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full name is required";
    } else if (formData.fullName.length < 2) {
      newErrors.fullName = "Name must be at least 2 characters";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name as keyof FormErrors]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setSubmitStatus("idle");

    if (validateForm()) {
      try {
        const response = await fetch(
          'https://5nccgz2fjylpvmzo7ztmqok6ma0oznec.lambda-url.us-east-1.on.aws/',
          {
            method: 'POST',
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setSubmitStatus("success");
        setFormData({ fullName: "", email: "", message: "" });
      } catch (error) {
        console.error("Submission error:", error);
        setSubmitStatus("error");
      }
    }

    setIsSubmitting(false);
  };

  const inputClassName = (fieldName: keyof FormErrors): string => `
    w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500
    ${errors[fieldName] ? "border-red-500" : "border-gray-300"}
  `;

  return (
    <section className="container lg:w-[80%] md:w-[76%] mx-auto md:pt-40 pt-20 px-4 md:px-0">
      <div className="md:grid grid-cols-2">
        <div className="mt-20">
          <p className="md:text-lg my-2 text-xl md:text-left text-center">
            Contact us
          </p>
          <h1 className="md:text-3xl text-2xl">
            Help is here when <br className="hidden md:block" /> you need it
          </h1>
        </div>
        <div className="my-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Full name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className={inputClassName("fullName")}
                disabled={isSubmitting}
              />
              {errors.fullName && (
                <p className="mt-1 text-sm text-red-600">{errors.fullName}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={inputClassName("email")}
                disabled={isSubmitting}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                className={inputClassName("message")}
                disabled={isSubmitting}
              />
              {errors.message && (
                <p className="mt-1 text-sm text-red-600">{errors.message}</p>
              )}
            </div>

            {submitStatus === "success" && (
              <div className="p-4 bg-green-50 border border-green-200 rounded-md">
                <p className="text-green-800">Message sent successfully!</p>
              </div>
            )}

            {submitStatus === "error" && (
              <div className="p-4 bg-red-50 border border-red-200 rounded-md">
                <p className="text-red-800">
                  Failed to send message. Please try again.
                </p>
              </div>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className={`
                md:w-3/4 w-full mx-auto flex items-center justify-center px-4 py-2 border border-transparent 
                rounded-md shadow-sm text-sm font-medium text-black bg-[#FCBF1E] 
                hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-yellow-500 ${
                  isSubmitting ? "opacity-70 cursor-not-allowed" : ""
                }
              `}
            >
              {isSubmitting ? "Sending..." : "Submit →"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
