import Banner from './Banner';
import Core from './Core';
import FAQ from './FAQ';
import Business from './Business';

const index = () => {
  return (
    <>
      <Banner />
      <Core />
      <Business />
      <FAQ />
    </>
  );
};

export default index;
