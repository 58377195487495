import React from 'react';
import { FaArrowRight } from 'react-icons/fa6';

interface EarlyAccessButtonProps {
  className?: string;
  onClick?: () => void;
}

const EarlyAccessButton: React.FC<EarlyAccessButtonProps> = ({
  className = '',
  onClick
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-yellow-400 text-black hover:bg-black hover:text-white rounded-xl px-6 py-3 md:px-4 md:py-2 mt-12 mx-auto flex items-center ${className}`}
    >
      Get early access
      <span className="ml-3">
        <FaArrowRight size={16} />
      </span>
    </button>
  );
};

export default EarlyAccessButton;
