import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/common/Layout';
import LandingPage from './components/landing-page';
import Pricing from './components/pricing';
import Contact from './components/contact';
import AboutUs from './components/about-us';
import Privacy from './components/contact/Privacy';
import Terms from './components/contact/Terms';

function App() {
  return (
    <Router>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <LandingPage />
              </Layout>
            }
          />
          <Route
            path='/pricing'
            element={
              <Layout>
                <Pricing />
              </Layout>
            }
          />
          <Route
            path='/about-us'
            element={
              <Layout>
                <AboutUs />
              </Layout>
            }
          />
          <Route
            path='/contact'
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route
            path='/terms'
            element={
              <Layout>
                <Terms />
              </Layout>
            }
          />
          <Route
            path='/privacy'
            element={
              <Layout>
                <Privacy />
              </Layout>
            }
          />
        </Routes>
    </Router>
  );
}

export default App;
