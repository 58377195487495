import { FaArrowRight, FaCheck } from 'react-icons/fa6';

const index = () => {
  const pro = [
    'Everything in the Free Plan, plus:',
    'Offline translation support.',
    'Access to text-to-speech and speech-to-text tools.',
    'Document translation for up to 10 documents/month',
  ];
  const basic = [
    'Real-time text translation between English, Yoruba, Igbo, and Hausa.',
    'Access to text-to-speech and speech-to-text tools.',
    'Basic voice translation (limited minutes per month).',
    'Language learning tools for Yoruba, Igbo, and Hausa.',
  ];
  const enterprise = [
    'Everything in the Pro Plan, plus:',
    'Unlimited document translation.',
    'Integration with your business systems',
    'Real-time API access for your organization.',
    'Custom solutions tailored to your business requirements.',
    'Dedicated account manager.',
  ];

  return (
    <section className='container mx-auto lg:w-[80%] md:w-[94%] w-[985] md:py-32 pt-28 pb-20 px-5 md:px-0'>
      <div className='text-center'>
        <h2 className='md:text-2xl text-3xl mb-4 mt-6 '>Pricing</h2>
        <p>
          Discover flexible plans designed to meet individual, professional, and
          organizational <br className='hidden md:block' /> needs. Choose the
          plan that works best for you!
        </p>
      </div>

      <div className='grid md:grid-cols-2 mx-auto md:space-x-10 md:mt-20 mt-12'>
        <div className='rounded-2xl px-8 py-10 bg-[#FCC01D] text-white'>
          <h2 className='text-2xl'>Pro</h2>
          <p>For professional use</p>
          <div className='grid lg:grid-cols-3 gap-x-5'>
            <ul className='space-y-4 md:col-span-2'>
              {pro.map((feature, index) => (
                <li
                  key={index}
                  className='flex items-center gap-3 text-sm mt-6'>
                  <div className='rounded-full border border-white p-1'>
                    <FaCheck className='w-2 h-2 text-white' />
                  </div>
                  <span className='text-white'>{feature}</span>
                </li>
              ))}
            </ul>

            <div className='flex flex-col justify-end mt-12'>
              <h2 className='text-2xl'>₦0/mo</h2>
              <button className='bg-[#191714] text-white md:text-sm rounded-lg px-4 md:py-2 py-3 md:mt-5 mt-3 flex justify-center w-2/3 md:w-fit'>
                Get started
                <span className='my-auto ms-3'>
                  <FaArrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className='mt-12 md:mt-0 border-[0.3px] rounded-2xl px-8 py-8 group relative text-black '>
          <h2 className='text-2xl'>Basic</h2>
          <p>For personal use</p>
          <div className='grid lg:grid-cols-3 gap-x-5'>
            <ul className='space-y-4 md:col-span-2'>
              {basic.map((feature, index) => (
                <li
                  key={index}
                  className='flex items-center gap-3 text-sm mt-6'>
                  <div className='rounded-full border border-black p-1'>
                    <FaCheck className='w-2 h-2 text-black' />
                  </div>
                  <span className='text-black'>{feature}</span>
                </li>
              ))}
            </ul>

            <div className='flex flex-col justify-end mt-12'>
              <h2 className='text-2xl'>₦0/mo </h2>
              <button className='bg-[#FCC01D] md:text-sm text-white rounded-lg px-4 md:py-2 py-3 md:mt-5 mt-3 flex justify-center md:mx-auto w-2/3 md:w-fit'>
                Get started
                <span className='my-auto ms-3'>
                  <FaArrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='border-[1px] rounded-lg md:mt-20 mt-12 md:py-12 py-10 px-8'>
        <h2 className='text-2xl'>Enterprise</h2>
        <small>For organizations with complex language needs</small>
        <div className='grid md:grid-cols-3 gap-x-5'>
          <ul className='space-y-4 md:col-span-2'>
            {enterprise.map((feature, index) => (
              <li key={index} className='flex items-center gap-3 text-sm mt-6'>
                <div className='rounded-full border border-black p-1'>
                  <FaCheck className='w-2 h-2' />
                </div>
                <span>{feature}</span>
              </li>
            ))}
          </ul>

          <div className='flex flex-col justify-end md:items-end mt-12 '>
            <p>Custom pricing based on requirements</p>
            <button className='bg-[#FCC01D] text-white md:text-sm rounded-lg px-4 md:py-2 py-3 md:mt-5 mt-3 flex justify-center w-2/3 md:w-fit '>
              Contact sales
              <span className='my-auto ms-3'>
                <FaArrowRight />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
