import React, { useState } from 'react';
import { FiMinus } from 'react-icons/fi';
import { FiPlus } from 'react-icons/fi';

interface AccordionItems {
  title: string;
  content: string;
}

interface AccordionItemProps extends AccordionItems {
  isOpen: boolean;
  onClick: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  content,
  isOpen,
  onClick,
}) => {
  return (
    <div className=' bg-[#F1F0EE] rounded-md my-1.5'>
      <button
        className='flex justify-between items-center w-full py-4 md:px-6 px-4 sm:text-base text-left'
        onClick={onClick}>
        <span className='font-medium'>{title}</span>
        {isOpen ? (
          <FiMinus className='h-5 w-5 my-auto text-gray-500' />
        ) : (
          <FiPlus className='h-5 w-5 my-auto text-gray-500' />
        )}
      </button>
      {isOpen && (
        <div className='py-4 md:px-6 px-4 bg-[#F1F0EE] '>
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

interface AccordionProps {
  items: AccordionItems[];
}

const AccordionContent: React.FC<AccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className='w-full md:max-w-xl mx-auto  overflow-hidden text-lg'>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={index === openIndex}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </div>
  );
};

const Accordion: React.FC = () => {
  const accordionItems: AccordionItems[] = [
    {
      title: 'What is Terngi.ai?',
      content:
        'Terngi.ai is an advanced AI-powered language translation and communication platform specifically designed for Nigerian languages. It focuses on translating between English and the three major Nigerian languages: Yoruba, Igbo, and Hausa.',
    },
    {
      title: `How accurate are Terngi.ai's translations?`,
      content:
        'Terngi.ai uses advanced AI models trained on extensive datasets of Nigerian languages. Our translations are highly accurate, capturing not just literal meanings but also cultural nuances, idioms, and context-specific expressions',
    },
    {
      title: 'Can Terngi.ai translate both text and speech?',
      content:
        'Yes, Terngi.ai offers both text and speech translation. You can input text or speak directly into the app for real-time translation.',
    },
    {
      title: 'Can Terngi.ai translate documents or websites?',
      content:
        'Yes, Terngi.ai can translate documents and web content. Simply upload your document or paste the website URL into the app for translation.',
    },
    {
      title: 'Is my data safe with Terngi.ai?',
      content:
        'We take data privacy seriously. All translations are processed securely, and we do not store personal content. Please refer to our privacy policy for more details.',
    },
    {
      title: 'When will Terngi.ai go live?',
      content:
        'Terngi.ai is scheduled to go live soon. Early users who signed up using the Get Early Access button will have access to the App.',
    },
  ];

  return <AccordionContent items={accordionItems} />;
};

const FAQ = () => {
  return (
    <main className='container mx-auto md:px-0 px-4'>
      <section className='md:mt-24 mt-20 py-5'>
        <div className='text-center'>
          <h1 className='md:text-3xl text-2xl font-medium tracking-wide '>
            FAQS
          </h1>
          <p className='my-3'>Some of the most common questions answered</p>
        </div>
        <div className='mt-8'>
          <Accordion />
        </div>
      </section>
    </main>
  );
};

export default FAQ;
