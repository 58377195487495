
import { useState } from 'react';
import VoiceIcon from '../assets/icons/voicerecicon.png';
import TranslateIcon from '../assets/icons/translateCont.png';
import { FaArrowRight } from 'react-icons/fa6';
import { PiWaveformBold } from 'react-icons/pi';
import { IoMicOutline } from 'react-icons/io5';
import { AccessFormModal } from './AccessForm';

const Core = () => {
  const [activeTab, setActiveTab] = useState('text-to-speech');
  const [isModalOpen, setIsModalOpen] = useState(false);


  const tabs = [
    {
      id: 'text-to-speech',
      title: 'Text to speech',
      subtitle: 'Give Your Words a Voice',
      heading: `Making written text accessible to those who can't read the language`,
      description: 'Transforms written text in into natural-sounding speech.',
      rightContent: (
        <div className='lg:w-1/3 md:w-1/2 min-h-80 rounded-2xl px-5 py-8 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
          <div className='absolute bottom-8 mt-12'>
            <span className='text-sm text-[#4B463B]'>English</span>
            <img src={VoiceIcon} alt='' className='w-auto h-12 object-fill ' />
          </div>

          <div className='absolute top-0 end-5 w-1/2 h-20 rounded-xl object-contain mt-12 bg-[#F4F6E5] pt-2.5 px-2'>
            <p className='text-[#878786ee] text-sm'>
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi.{' '}
            </p>
          </div>
        </div>
      ),
    },
    {
      id: 'speech-to-text',
      title: 'Speech to text',
      subtitle: 'Give Your Words a Voice',
      heading: 'Instant conversion from speech to written text',
      description: 'Transforms written text in into natural-sounding speech.',
      rightContent: (
        <div className='lg:w-1/3 md:w-1/2  min-h-80 text-center rounded-2xl px-5 py-8 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
          <div className='p-6 w-fit mx-auto object-contain flex justify-center rounded-full bg-[#ffffffe6] text-center mt-20 mb-4'>
            <IoMicOutline className='text-[#FCC01D] text-3xl' />
          </div>
          <p>Say something</p>
          <div className='flex justify-center mx-auto'>
            <PiWaveformBold className='text-4xl text-[#FCC01D] opacity-70' />
          </div>
        </div>
      ),
    },
    {
      id: 'document-translation',
      title: 'Document translation',
      subtitle: 'From Foreign Files to Familiar Words',
      heading: 'Quick conversion of documents',
      description:
        'Translates entire documents while preserving their original formatting and structure.',
      rightContent: (
        <div className='lg:w-1/3 md:w-1/2  min-h-80 rounded-2xl px-5 py-8 relative text-center bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
          <div className='absolute bottom-5 space-y-1'>
            <div className='bg-[#F4F6E5] py-3 px-6 rounded-md border-[1px] border-dashed border-[#D3D0C7] '>
              <p className='text-[#878786ee] text-sm'>
                Drop file here to translate
              </p>
              <p className='text-[#cccccac9] text-sm'>
                all .pdf, .docx and .docs file types are supported
              </p>
            </div>
            <div>
              <input
                type='text'
                disabled
                placeholder='Enter text'
                className='py-3 placeholder:text-[#bebeb9d3] px-4 w-full rounded-lg placeholder:text-sm'
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 'localization',
      title: 'Localization of content',
      subtitle: 'Browse the Web in your language',
      heading: 'Turn Any Web content Into Your Native Language',
      description:
        'Specializes in translating and adapting web content for seamless browsing experience',
      rightContent: (
        <div className='lg:w-1/3 md:w-1/2  min-h-80 rounded-2xl px-5 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
          <img
            src={TranslateIcon}
            alt=''
            className='w-full h-full object-contain'
          />
        </div>
      ),
    },
  ];

  const activeContent = tabs.find(tab => tab.id === activeTab);

  return (
    <section className='container mx-auto lg:w-[80%] md:w-[94%] w-[97%] md:py-20'>
      {isModalOpen && (
        <AccessFormModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <h1 className='md:text-4xl text-3xl text-center mb-4 md:mb-0'>
        Core Capabilities
      </h1>
      <p className='md:w-2/3 mx-auto text-center mb-20'>
        Terngi is an AI assistant that bridges language gaps, fostering
        understanding and connection across cultures. It instantly translates
        conversations, documents, and messages.
      </p>

      {/* Tab Navigation */}
      <div className='hidden md:flex justify-between mb-12'>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`pb-2 relative ${
              activeTab === tab.id
                ? 'font-medium'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab(tab.id)}>
            {tab.title}
            {activeTab === tab.id && (
              <div className='absolute bottom-0 left-0 w-full h-0.5 bg-[#FCBF1E]'></div>
            )}
          </button>
        ))}
      </div>

      {/* Mobile Accordion */}
      <div className='md:hidden space-y-8'>
        <div className='space-y-12 px-3 object-contain pb-14'>
          <div>
            <p className='underline underline-offset-8 decoration-[#FCBF1E] mb-8'>
              Text to speech
            </p>
            <i className='font-extralight opacity-50'>
              Give Your Words a Voice
            </i>
            <h1 className='my-4 text-xl'>
              Making written text accessible to those who can't read the
              language
            </h1>
            <p>Transforms written text in into natural-sounding speech.</p>
            <button
              onClick={() => setIsModalOpen(true)}
              className='bg-[#FCBF1E] text-black rounded-lg px-4 py-2 mt-12  flex font-light'>
              Get early access{' '}
              <span className='my-auto ms-3'>
                <FaArrowRight />
              </span>
            </button>
          </div>

          <div className='h-72 rounded-2xl px-5 py-8 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
            <div className='absolute bottom-8'>
              <span className='text-sm text-[#4B463B]'>English</span>
              <img
                src={VoiceIcon}
                alt=''
                className='w-auto h-12 object-fill '
              />
            </div>
            <div className='absolute top-0 end-5 w-2/3 h-20 rounded-xl object-contain mt-10 bg-[#F4F6E5] pt-2.5 px-2'>
              <p className='text-[#878786ee] text-sm'>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi.{' '}
              </p>
            </div>
          </div>
        </div>

        <div className='space-y-12 px-3 object-contain pb-14'>
          <div>
            <p className='underline underline-offset-8 decoration-[#FCBF1E] mb-8'>
              Speech to text
            </p>
            <i className='font-extralight opacity-50'>
              Give Your Words a Voice
            </i>
            <h1 className='my-4 text-xl'>
              Instant conversion from speech to written text
            </h1>
            <p>Transforms written text in into natural-sounding speech.</p>
            <button
              onClick={() => setIsModalOpen(true)}
              className='bg-[#FCBF1E] text-black rounded-lg px-4 py-2 mt-12  flex font-light'>
              Get early access{' '}
              <span className='my-auto ms-3'>
                <FaArrowRight />
              </span>
            </button>
          </div>

          <div className='h-72 text-center rounded-2xl px-5 py-8 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
            <div className='p-6 w-fit mx-auto object-contain flex justify-center rounded-full bg-[#ffee] text-center mt-20  mb-4'>
              <IoMicOutline className='text-[#FCC01D] text-3xl' />
            </div>
            <p>Say something</p>
            <div className='flex justify-center mx-auto'>
              <PiWaveformBold className='text-4xl text-[#FCC01D] opacity-70' />
            </div>
          </div>
        </div>

        <div className='space-y-12 px-3 object-contain pb-14'>
          <div>
            <p className='underline underline-offset-8 decoration-[#FCBF1E] mb-8'>
              Document translation
            </p>
            <i className='font-extralight opacity-50'>
              From Foreign Files to Familiar Words
            </i>
            <h1 className='my-4 text-xl'>
              Quick conversion of documents
            </h1>
            <p>
              Translates entire documents while preserving their original
              formatting and structure.
            </p>
            <button
              onClick={() => setIsModalOpen(true)}
              className='bg-[#FCBF1E] text-black rounded-lg px-4 py-2 mt-12  flex font-light'>
              Get early access{' '}
              <span className='my-auto ms-3'>
                <FaArrowRight />
              </span>
            </button>
          </div>

          <div className='h-72 rounded-2xl px-4 py-8 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
            <div className='absolute bottom-5 space-y-1 w-11/12'>
              <div className='bg-[#F4F6E5] py-3 px-3 rounded-md border-[1px] border-dashed border-[#D3D0C7]'>
                <p className='text-[#878786ee] text-sm'>
                  Drop file here to translate
                </p>
                <p className='text-[#cccccac9] text-sm'>
                  all .pdf, .docx and .docs file types are supported
                </p>
              </div>
              <div>
                <input
                  type='text'
                  disabled
                  placeholder='Enter text'
                  className='py-3 placeholder:text-[#bebeb9d3] px-4 w-full rounded-lg placeholder:text-sm'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='space-y-12 px-3 object-contain '>
          <div >
            <p className='underline underline-offset-8 decoration-[#FCBF1E] mb-8'>
              Localization of content
            </p>
            <i className='font-extralight opacity-50'>
              Browse the Web in your language
            </i>
            <h1 className='my-4 text-xl'>
              Turn Any Web content Into Your Native Language
            </h1>
            <p>
              Specializes in translating and adapting web content for seamless
              browsing experience
            </p>
          </div>
          <div className='h-72 rounded-2xl p-5 relative bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F]'>
            <img
              src={TranslateIcon}
              alt=''
              className='w-full h-full object-contain'
            />
          </div>
        </div>
      </div>

      {/* Desktop Content */}
      <div className='hidden md:flex justify-between mt-16 pb-16 object-contain'>
        <div className='lg:w-1/3 md:w-2/5'>
          <i className='font-extralight opacity-50 block'>
            {activeContent?.subtitle}
          </i>
          <h1 className='my-4 text-xl md:text-2xl'>{activeContent?.heading}</h1>
          <p>{activeContent?.description}</p>
          <button
            onClick={() => setIsModalOpen(true)}
            className='bg-[#FCBF1E] text-black rounded-lg px-4 py-2 mt-12 flex font-light'>
            Get early access
            <span className='my-auto ms-3'>
              <FaArrowRight />
            </span>
          </button>
        </div>
        {activeContent?.rightContent}
      </div>
    </section>
  );
};

export default Core;