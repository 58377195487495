import { Disclosure } from '@headlessui/react';
import { IoCloseSharp, IoMenu } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/icons/logo-text.png';

export default function Navigate() {
  const location = useLocation();

  const isActiveLink = (url: string) => {
    // Handle home page special case
    if (url === '/' && location.pathname === '/') {
      return true;
    }
    // For other pages, check if the current path matches the URL
    return url !== '/' && location.pathname === url;
  };

  return (
    <Disclosure
      as='nav'
      className='z-10 fixed w-full bg-[#ffffff8b] pt-0 px-3 md:px-0'>
      {({ open }) => (
        <>
          <div className='container lg:w-[100%] md:w-[98%] mx-auto border-[1px] rounded-2xl px-4 md:py-3 py-2 mt-5'>
            <div className='px-2 md:px-0'>
              <div className='relative flex items-center justify-between'>
                <div className='flex flex-1 items-center'>
                  <Link to='/' className={isActiveLink('/') ? 'font-bold' : ''}>
                    <img
                      className='md:h-8 h-6 w-auto my-1'
                      src={logo}
                      alt='Terngi ai'
                    />
                  </Link>
                </div>

                <div className='flex items-center sm:hidden'>
                  <Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white z-30'>
                    <span className='absolute -inset-2' />
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <IoCloseSharp
                        className='block h-8 w-8 border-0'
                        color='#ffff'
                        aria-hidden='true'
                      />
                    ) : (
                      <IoMenu
                        className='block h-6 w-6'
                        color='#000'
                        aria-hidden='true'
                      />
                    )}
                  </Disclosure.Button>
                </div>
                
                <div className='hidden md:flex md:space-x-8 items-center justify-center sm:items-stretch sm:justify-start'>
                  <Link
                    to='/about-us'
                    className={`my-auto tracking-wider ${
                      isActiveLink('/about-us') ? 'font-bold' : ''
                    }`}>
                    About us
                  </Link>
                  <Link
                    to='/pricing'
                    className={`my-auto tracking-wider ${
                      isActiveLink('/pricing') ? 'font-bold' : ''
                    }`}>
                    Pricing
                  </Link>
                  <Link
                    to='/contact'
                    className={`border-[1px] border-black md:hover:bg-black bg-black md:bg-transparent md:hover:border-white md:hover:text-white text-white rounded-xl md:text-black mx-auto md:px-5 px-4 py-1 ${
                      isActiveLink('/contact') ? 'font-bold' : ''
                    }`}>
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className=''>
            <div className='space-y-10 px-2 pb-3 pt-32 h-screen text-white w-2/3 end-0 top-0 absolute bg-gradient-to-b from-[#B1D690] via-[#F8E9BE] to-[#B3D68F] disclosure shadow-2xl z-20'>
              <div className='px-2 pt-2 pb-3 space-y-1'>
                <Disclosure.Button
                  as={Link}
                  to='/about-us'
                  className={`block px-3 py-2 rounded-md text-lg font-medium ${
                    isActiveLink('/about-us')
                      ? 'font-bold text-black bg-slate-50 p-4 rounded-4xl'
                      : ''
                  }`}>
                  About us
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to='/pricing'
                  className={`block px-3 py-2 rounded-md text-lg font-medium ${
                    isActiveLink('/pricing')
                      ? 'font-bold text-black bg-slate-50 p-4 rounded-4xl'
                      : ''
                  }`}>
                  Pricing
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to='/contact'
                  className={`block px-3 py-2 rounded-md text-lg font-medium ${
                    isActiveLink('/contact')
                      ? 'font-bold text-black bg-slate-50 p-4 rounded-4xl'
                      : ''
                  }`}>
                  Contact us
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>

          {/* Drop shadow backdrop */}
          {open && (
            <div className='fixed inset-0 bg-black/5 backdrop-blur-sm bg-opacity-75 transition-opacity z-10 shadow-2xl'></div>
          )}
        </>
      )}
    </Disclosure>
  );
}
