import { Link } from 'react-router-dom';
import logo from '../assets/icons/logo-text.png';
// import { FaArrowRight } from 'react-icons/fa6';
import TransText from '../assets/icons/lang-text.png';
import TransBg from '../assets/translate-bg.png';
import EarlyAccessButton from './EarlyAccessButton';
import { useState } from 'react';
import { AccessFormModal } from '../landing-page/AccessForm';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <footer className='bg-[#0D5260] relative'>
      {isModalOpen && (
        <AccessFormModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <>
        <img src={TransBg} alt='' className='absolute top-0 h-3' />
        <section className='container lg:w-[98%] md:w-[96%] mx-auto md:py-10 md:px-3 px-5 py-8 mt-24 md:text-center'>
          <div className='mt-8'>
            <h1 className='md:text-4xl text-2xl font-medium tracking-wider text-[#C1F4FF]'>
              Speak - Terngi Translates
            </h1>
            <img
              src={TransText}
              alt=''
              className='md:w-1/3 mx-auto mt-8 mb-6'
            />
            <p className='md:w-9/12 mx-auto text-[#C9EEF5] opacity-80 text-center'>
              More languages would be added as we move on as we plan to
            </p>
            <EarlyAccessButton
              onClick={() => setIsModalOpen(true)}
              className='!bg-[#106879] !text-white rounded-2xl md:w-2/3 w-11/12 md:py-6 py-4 px-6 font-light mt-6 md:text-4xl text-xl  mx-auto flex justify-between '
            />
          </div>
          {/* mobile link */}
          <div className='md:hidden mt-12 mb-8 text-[#9DE0ED] opacity-90 '>
            <ul className='space-y-4'>
              <li>
                <Link to='/pricing'>Pricing</Link>{' '}
              </li>
              <li>
                <Link to='/about-us'>About</Link>{' '}
              </li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className='flex flex-1 items-center mt-12 '>
            <Link to='/'>
              <img
                className='md:h-8 h-6 w-auto my-1 '
                src={logo}
                alt='Terngi ai'
              />
            </Link>
          </div>
          <ul className='md:flex md:flex-row md:justify-between text-lg mt-3 text-[#9DE0ED] opacity-90'>
            <div className='md:flex md:space-x-3 '>
              <li>{new Date().getFullYear()} Terngi.ai </li>
              <li>
                <Link to='/terms'>Terms of service</Link>
              </li>
              <li>
                <Link to='/privacy'>Privacy</Link>
              </li>
            </div>
            <div className='hidden md:flex space-x-3 '>
              <li>
                <Link to='/pricing'>Pricing</Link>{' '}
              </li>
              <li>
                <Link to='/about-us'>About</Link>{' '}
              </li>
              <li>Contact us</li>
            </div>
          </ul>
        </section>
        <img src={TransBg} alt='' className='absolute bottom-0 h-3' />
      </>
    </footer>
  );
};

export default Footer;
